
import CambiarDemarcacionModal from "@/components/modals/CambiarDemarcacionModal.vue";
import Menor from "@/components/solicitudes/Menor.vue";
import comarcas from "@/core/data/comarcas";
import {
  numeroMenoresOpciones,
  numeroMenoresOpcionesTodas,
} from "@/core/data/numeroMenores";
import {
  tipologiasFamilia,
  tipologiasFamiliaTodas,
} from "@/core/data/tipologiasFamilia";
import tratamientos from "@/core/data/tratamientos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { DatosSolicitudFamilia } from "@/store/models";
import { AuthModule, FamiliasFormulariosModule } from "@/store/modules";
import { ArrowDown } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Formulario {
  notas: string;
}

export default defineComponent({
  name: "formularios-familia-detalle",
  components: {
    ErrorMessage,
    Menor,
    Page,
    ArrowDown,
    CambiarDemarcacionModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    let token = route.params.id;

    const store = useStore();
    const module = getModule(FamiliasFormulariosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const error = computed(() => module.errors);
    const notasLoading = ref(false);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaDetalle = ref(false);
    const estado = ref<string>("");
    const claseEstado = ref<string>("");

    onBeforeRouteUpdate(async (params) => {
      cargaDetalle.value = true;
      try {
        token = params.params.id;
        await module.fetchById({ comarcaId, id: token as string });
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId, id: token as string });
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const familia = computed(() => module.familiaFormulario);

    const datosSolicitud = computed(
      () => familia?.value?.datosSolicitud ?? ({} as DatosSolicitudFamilia)
    );
    const libroFamilia = computed(
      () => familia?.value?.datosAdjuntos?.libroFamilia ?? []
    );

    const ingresos = computed(
      () => familia.value?.datosSolicitud?.ingresos || "Sin ingresos"
    );
    const familiaComarcaId = ref<string>();
    const menores = computed(() => {
      let menores = familia.value?.datosSolicitud?.menores ?? [];
      if (menores.length > 0 && familia.value?.servicioProgramado) {
        return menores.map((menor) => {
          menor.servicioProgramado = familia.value?.servicioProgramado;
          return menor;
        });
      }
      return menores;
    });

    const peticionFormularioId = computed(
      () => familia?.value?.peticionFormularioId ?? ""
    );

    const tratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === familia.value?.datosSolicitud?.tratamiento
      );

      return result ?? tratamientos[3];
    });
    const familiaTipologiaFamilia = computed(() => {
      return (
        tipologiasFamilia.find(
          (tr) => tr.id === familia.value?.datosSolicitud?.tipologiaFamilia
        ) || tipologiasFamiliaTodas[6]
      );
    });
    const familiaNumeroMenores = computed(() => {
      return (
        numeroMenoresOpciones.find(
          (tr) => tr.id === familia.value?.datosSolicitud?.numeroMenores
        ) || numeroMenoresOpcionesTodas[9]
      );
    });
    const schema = Yup.object({ notas: Yup.string().ensure() });

    const { resetForm, handleSubmit } = useForm<Formulario>({
      validationSchema: schema,
    });

    const { value: notas } = useField("notas");

    watch(familia, () => {
      if (!familia.value) return;

      estado.value = familia.value?.estado.replaceAll("_", " ");
      switch (estado.value.replaceAll(" ", "_")) {
        case "PENDIENTE":
          claseEstado.value = "badge badge-primary";
          break;
        case "INCORPORADO":
          claseEstado.value = "badge badge-success";
          break;
        case "NO_APTO":
        case "REALIZADO_POR_ERROR":
          claseEstado.value = "badge badge-danger";
          break;
        default:
          claseEstado.value = "d-none";
          break;
      }
      familiaComarcaId.value = familia.value?.comarcaId;

      resetForm({ values: { notas: familia.value.notas ?? "" } });
      setCurrentPageBreadcrumbs(familia.value?.codigo ?? "", [
        t("menu.solicitudes"),
        { title: t("menu.familias"), path: "/solicitudes/familias" },
      ]);
    });

    const isLoadingAprobar = ref<boolean>(false);
    const isLoadingNoApto = ref<boolean>(false);
    const isLoadingPendiente = ref<boolean>(false);
    const isLoadingRealizadoPorError = ref<boolean>(false);

    return {
      isAdmin: !comarcaId,
      tratamiento,
      familia,
      datosSolicitud,
      libroFamilia,
      familiaTipologiaFamilia,
      familiaNumeroMenores,
      ingresos,
      familiaComarcaId,
      menores,
      notas,
      notasLoading,
      peticionFormularioId,
      FormularioEstado,
      comarcas,
      tipologiasFamilia,
      aprobar: async () => {
        isLoadingAprobar.value = true;
        try {
          await module
            .aprobar({
              comarcaId: comarcaId ?? familia.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t("formularios.familiaDetalle.aprobar.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/familias");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.aprobar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingAprobar.value = false;
        }
      },
      noApto: async () => {
        isLoadingNoApto.value = true;
        try {
          await module
            .noApto({
              comarcaId: comarcaId ?? familia.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t("formularios.familiaDetalle.descartar.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/familias");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.descartar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingNoApto.value = false;
        }
      },
      realizadoPorError: async () => {
        isLoadingRealizadoPorError.value = true;
        try {
          await module
            .realizadoPorError({
              comarcaId: comarcaId ?? familia.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t(
                    "formularios.familiaDetalle.realizadoPorError.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/familias");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.descartar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingRealizadoPorError.value = false;
        }
      },
      pendiente: async () => {
        isLoadingPendiente.value = true;
        try {
          await module
            .pendiente({
              comarcaId: comarcaId ?? familia.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t("formularios.familiaDetalle.pendiente.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/familias");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.pendiente.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingPendiente.value = false;
        }
      },
      isLoadingAprobar,
      isLoadingNoApto,
      isLoadingPendiente,
      isLoadingRealizadoPorError,
      cargaDetalle,
      estado,
      claseEstado,
      changeComarca: async (newValue) => {
        try {
          await module.cambiarComarca({
            id: token as string,
            comarcaId: newValue,
          });

          Swal.fire({
            text: t("formularios.familiaDetalle.changeComarca.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.cambiarComarca.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      },
      onSubmit: handleSubmit(async (values) => {
        notasLoading.value = true;
        try {
          await module.update({
            comarcaId: comarcaId ?? familia.value?.comarcaId ?? "",
            id: token as string,
            data: values,
          });
          Swal.fire({
            text: t("formularios.familiaDetalle.guardar.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.guardar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          notasLoading.value = false;
        }
      }),
    };
  },
});
