
import comarcas from "@/core/data/comarcas";
import { useModal } from "@/composable/useModal";
import { hideModal } from "@/core/helpers/dom";
import { AuthModule, FamiliasListadoModule } from "@/store/modules";
import Swal from "sweetalert2";
import { useField, useForm } from "vee-validate";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";
import { FamiliaListado } from "@/store/models";
import router from "@/router";

interface FormValues {
  familiaComarcaId: string;
  mensaje?: string;
}

export default defineComponent({
  name: "modal-cambiar-demarcacion",
  props: {
    familia: {
      type: Object as () => FamiliaListado,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const module = getModule(FamiliasListadoModule, store);
    const { t } = useI18n();
    const { modalRef, isShown } = useModal();

    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    watch(
      () => [props.familia, isShown.value],
      () => {
        if (!props.familia) return;
        resetForm({
          values: {
            familiaComarcaId: props.familia.comarcaId,
            mensaje: "",
          },
        });
      }
    );

    const validationSchema = Yup.object({
      familiaComarcaId: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.comarca.label")),
      mensaje: Yup.string().label(t("formularios.familiaDetalle.nombre.label")),
    });
    const { handleSubmit, resetForm, meta, errors } = useForm<FormValues>({
      validationSchema,
    });
    const { value: familiaComarcaId } = useField("familiaComarcaId");
    const { value: mensaje } = useField("mensaje");

    const loading = ref(false);
    const submit = handleSubmit(async (values) => {
      if (!props.familia?.comarcaId || !props.familia?.id) return;
      try {
        loading.value = true;
        await module.updateComarca({
          comarcaId: comarcaId ?? props.familia.comarcaId ?? "",
          id: props.familia.id,
          data: values,
        });
        if (module.errors) {
          throw module.errors;
        } else {
          await Swal.fire({
            text: t("dialogs.cambiarDemarcacion.success"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-primary",
            },
          });
        }
      } catch (error) {
        Swal.fire({
          text: t("dialogs.cambiarDemarcacion.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        loading.value = false;
        hideModal(modalRef.value);
        router.push("/solicitudes/familias");
      }
    });

    return {
      familiaComarcaId,
      mensaje,
      meta,
      submit,
      loading,
      comarcas,
      errors,
      modalRef,
    };
  },
});
