
import { dias, horarios } from "@/core/data/tiemposFamilias";
import tratamientos from "@/core/data/tratamientos";
import { Menor, ServicioProgramado } from "@/store/models";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "formularios-familia-detalle-menor-item",
  props: {
    menor: { type: Object as () => Menor, required: true },
  },
  setup(props) {
    const { t } = useI18n();

    const menoresSimple = computed(
      () => typeof props.menor?.lugarPrestacion === "undefined"
    );

    const tratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === props.menor?.tratamiento
      );

      return result ?? tratamientos[3];
    });

    return {
      tratamiento,
      menorData: computed(() => props.menor),
      direccion: computed(
        () =>
          props.menor?.direccion ||
          props.menor?.servicioProgramado?.servicio.direccion
      ),
      servicioProgramado: computed(
        () => props.menor?.servicioProgramado || ({} as ServicioProgramado)
      ),
      lugarPrestacion: computed(() => {
        const lugarPrestacion = props.menor?.lugarPrestacion;
        const lugarEspacioColectivo = props.menor?.lugarEspacioColectivo;
        const otrosEspacioColectivo = props.menor?.otrosEspacioColectivo;

        if (lugarPrestacion !== "ESPACIO") {
          return t(`lugarPrestacion.${lugarPrestacion}`);
        }

        if (lugarEspacioColectivo !== "OTROS") {
          return t(`lugarEspacioColectivo.${lugarEspacioColectivo}`);
        }

        return otrosEspacioColectivo ?? "";
      }),
      fechas: computed(() => {
        const { inicioPrevisto, finPrevisto } = props.menor ?? {};
        if (!inicioPrevisto || !finPrevisto) return [];

        return [inicioPrevisto, finPrevisto];
      }),
      dias,
      horarios,
      menoresSimple,
    };
  },
});
